import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Business, BusinessApi } from "../clients/business_api";
import { Coupon, CouponApi } from "../clients/coupon_api";
import { config } from "../config";

interface PageProps extends
  RouteComponentProps<{
    id: string;
  }> {}

const BusinessPage: React.FC<PageProps> = ({ match }) => {
  const id = match.params.id;
  const [business, setBusiness] = useState<Business | null>();
  const [coupons, setCoupons] = useState([] as Coupon[]);

  useEffect(() => {
    const getCoupons = async (businessId: string) => {
      const api = new CouponApi(config.API_ORIGIN);
      const result = await api.listForBusiness(businessId);
      const filtered = result.filter((c) => c.expiresOn > new Date());
      setCoupons(filtered);
    };

    const getBusiness = async (businessId: string) => {
      const api = new BusinessApi(config.API_ORIGIN);
      const result = await api.get(businessId);
      setBusiness(result);
    };

    getBusiness(id);
    getCoupons(id);
  }, [id]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{business?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!coupons.length && (
          <div className="p-4 text-center">
            No coupons yet...
          </div>
        )}
        {coupons.length > 0 && (
          <>
            <div className="mx-auto max-w-lg">
              <h2 className="mx-5 mt-4 uppercase">Coupons</h2>
            </div>
            <IonGrid>
              {coupons.map((c, idx) => (
                <IonRow key={idx} className="mx-auto max-w-lg">
                  <CouponCard coupon={c} />
                </IonRow>
              ))}
            </IonGrid>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

const CouponCard = (props: { coupon: Coupon }) => {
  const coupon = props.coupon;
  return (
    <IonCard
      routerLink={`/home/coupons/${coupon.id}`}
      routerDirection="forward"
      className="w-full my-2"
    >
      <IonCardContent className="p-0">
        <div className="px-4 mt-4 flex items-center justify-between">
          <div className="inline-block text-2xl font-semibold">
            ${coupon.discountPrice}
          </div>
        </div>
        <div className="px-4 mt-2 mb-4">{coupon.description}</div>
        <div className="px-4 py-2 text-center text-xs uppercase bg-blend-darken bg-neutral-800">
          expires on {coupon.expiresOn.toLocaleDateString()}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default BusinessPage;
