import {
  IonBadge,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonThumbnail,
} from "@ionic/react";
import { Geolocation } from "@capacitor/geolocation";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import getDistance from "geolib/es/getDistance";
import { Business, BusinessApi } from "../clients/business_api";
import { config } from "../config";

type Kilometers = number;
type LatLng = { lat: number; lng: number };

const BusinessList: React.FC<RouteComponentProps> = () => {
  const [businesses, setBusinesses] = useState([] as Business[]);
  const [position, setPosition] = useState<LatLng>();

  useEffect(() => {
    const getPosition = async () => {
      const { coords } = await Geolocation.getCurrentPosition();
      const value = { lat: coords.latitude, lng: coords.longitude };
      setPosition(value);
    };

    getPosition();
  }, []);

  useEffect(() => {
    const getBusinesses = async () => {
      const api = new BusinessApi(config.API_ORIGIN);
      const businesses = await api.list();
      setBusinesses(businesses);
    };

    getBusinesses();
  }, []);

  const distance = (
    from: LatLng | undefined,
    to: LatLng | undefined,
  ): Kilometers => {
    if (!from || !to) return NaN;
    return getDistance(from, to) / 1000;
  };

  const howFar = (from: LatLng | undefined, to: LatLng | undefined) => {
    const km = distance(from, to);
    if (isNaN(km)) return null;
    return `${km.toFixed(1)}km`;
  };

  const shortAddress = (address: string) => {
    const parts = address.split(",");
    return parts.shift();
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonList className="max-w-lg mx-auto">
          {businesses.map((b) => (
            <IonItem
              key={b.id}
              routerLink={`/home/businesses/${b.id}`}
              routerDirection="forward"
            >
              <IonThumbnail slot="start">
                <IonImg src={b.logoUri} className="object-scale-down"></IonImg>
              </IonThumbnail>
              <IonLabel>
                <h3>{b.name}</h3>
                <p>
                  {shortAddress(b.address)}
                </p>
                <p className="text-xs"></p>
              </IonLabel>
              {position &&
                (
                  <IonBadge color="tertiary">
                    {howFar(position, b.latLng)}
                  </IonBadge>
                )}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default BusinessList;
