import { IonRouterOutlet } from "@ionic/react";
import { Route, RouteComponentProps } from "react-router";
import BusinessList from "../components/business_list";
import BusinessPage from "../components/business_page";
import CouponPage from "../components/coupon_page";

const HomePage: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonRouterOutlet>
      <Route exact path={match.url} component={BusinessList} />
      <Route path={`${match.url}/businesses/:id`} component={BusinessPage} />
      <Route path={`${match.url}/coupons/:id`} component={CouponPage} />
    </IonRouterOutlet>
  );
};

export default HomePage;
