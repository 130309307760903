export interface Business {
  id: string;
  name: string;
  address: string;
  latLng: { lat: number; lng: number };
  /** Contact person first name */
  firstName: string;
  /** Contact person last name */
  lastName: string;
  phone: string;
  email: string;
  logoUri: string;
  googlePlaceId: string;
}

export class BusinessApi {
  constructor(private origin: string) {}

  async list(): Promise<Business[]> {
    const response = await fetch(`${this.origin}/businesses`);
    const businesses = await response.json() as Business[];
    return businesses;
  }

  async get(id: string): Promise<Business | null> {
    const response = await fetch(`${this.origin}/businesses/${id}`);

    if (!response.ok) {
      return null;
    }

    const business = await response.json() as Business;
    return business;
  }

  async add(business: Business): Promise<Business> {
    await fetch(`${this.origin}/businesses`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(business),
    });
    return business;
  }

  async put(business: Business): Promise<Business> {
    await fetch(`${this.origin}/businesses/${business.id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(business),
    });
    return business;
  }
}
