import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import QRCode from "qrcode";
import { Business, BusinessApi } from "../clients/business_api";
import { Coupon, CouponApi } from "../clients/coupon_api";
import { config } from "../config";

interface PageProps extends
  RouteComponentProps<{
    id: string;
  }> {}

const CouponPage: React.FC<PageProps> = ({ match }) => {
  const id = match.params.id;
  const [coupon, setCoupon] = useState<Coupon>();
  const [business, setBusiness] = useState<Business>();
  const qr = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const getCoupon = async (couponId: string) => {
      const api = new CouponApi(config.API_ORIGIN);
      const result = await api.get(couponId);
      setCoupon(result);
    };

    getCoupon(id);
  }, [id]);

  useEffect(() => {
    const generateQr = (text: string) => {
      QRCode.toCanvas(qr.current, text, {
        scale: 3,
        margin: 2,
        color: { dark: "#eee", light: "#000" },
      });
    };

    generateQr(window.location.href ?? "");
  }, [id]);

  useEffect(() => {
    const getBusiness = async (businessId: string) => {
      const api = new BusinessApi(config.API_ORIGIN);
      const result = await api.get(businessId);
      if (result) setBusiness(result);
    };
    if (coupon) getBusiness(coupon?.businessId);
  }, [coupon]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Coupon</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="mx-auto max-w-lg">
          <div
            id="business"
            className="m-4 flex flex-row items-center"
          >
            <img
              src={business?.logoUri}
              alt={business?.name}
              className="rounded-lg object-scale-down w-20"
            />
            <div className="ml-3">
              <h1 className="text-lg font-semibold">{business?.name}</h1>
              <p className="text-sm">{business?.address}</p>
              <a href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${business?.googlePlaceId}`} target="_blank" rel="noreferrer">Map</a>
            </div>
          </div>

          <div
            id="coupon"
            className="m-4 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700"
          >
            <div className="p-3 text-center">
              <h2 className="text-2xl">
                {coupon?.title}
              </h2>
              <p className="my-3">{coupon?.description}</p>
              <div className="my-2 text-sm uppercase">
                Expires on {coupon?.expiresOn.toLocaleDateString()}
              </div>
              <div className="my-8 text-5xl">
                ${coupon?.discountPrice}
              </div>
            </div>
            <div className="border-t-2 border-dashed py-8 text-center">
              <canvas ref={qr} className="mx-auto text-white"></canvas>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CouponPage;
