export interface Coupon {
  id: string;
  businessId: string;
  title: string;
  description?: string;
  legal?: string;
  originalPrice: number;
  discountPrice: number;
  expiresOn: Date;
  barcode: string;
}

export class CouponApi {
  private readonly route: string;

  constructor(private origin: string) {
    this.route = `${origin}/coupons`;
  }

  async list(signal?: AbortSignal): Promise<Coupon[]> {
    const response = await fetch(this.route, { signal });
    const coupons = await response.json() as Coupon[];
    coupons.map((coupon) => {
      coupon.expiresOn = new Date(coupon.expiresOn);
      return coupon;
    });
    return coupons;
  }

  async listForBusiness(
    businessId: string,
    options?: { signal?: AbortSignal },
  ): Promise<Coupon[]> {
    const url = `${this.origin}/businesses/${businessId}/coupons`;
    const response = await fetch(url, { signal: options?.signal });
    const coupons = await response.json() as Coupon[];
    coupons.map((coupon) => {
      coupon.expiresOn = new Date(coupon.expiresOn);
      return coupon;
    });
    return coupons;
  }

  async get(id: string): Promise<Coupon> {
    const response = await fetch(`${this.route}/${id}`);
    const coupon = await response.json() as Coupon;
    coupon.expiresOn = new Date(coupon.expiresOn);
    return coupon;
  }

  async add(coupon: Coupon): Promise<Coupon> {
    const response = await fetch(`${this.route}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(coupon),
    });
    return await response.json();
  }

  async delete(id: string) {
    await fetch(`${this.route}/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  }

  async redeem(couponId: string, businessId: string): Promise<string> {
    const response = await fetch(`${this.route}/${couponId}/redemptions`, {
      method: "POST",
      body: JSON.stringify({ businessId: businessId }),
    });
    return await response.text();
  }

  async countRedemptions(id: string): Promise<number> {
    const response = await fetch(`${this.route}/${id}/redemptions`);
    return await response.json();
  }
}
